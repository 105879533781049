import { DeleteOutlined, ImageOutlined } from '@mui/icons-material';
import {
  Box,
  Divider,
  Typography,
  InputBase,
  useTheme,
  Button,
  IconButton,
  Grid,
  Modal,
} from '@mui/material';
import FlexBetween from 'components/FlexBetween';
import Dropzone from 'react-dropzone';
import UserImage from 'components/UserImage';
import WidgetWrapper from 'components/WidgetWrapper';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPosts } from 'state';
import { useNavigate } from 'react-router-dom';
import instance from '../../axios/api';
import compressImage from 'util/ImageCompressor';

const MyPostWidget = ({ picturePath }) => {
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isImage, setIsImage] = useState(false);
  const [images, setImages] = useState([]);
  const [post, setPost] = useState('');
  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;

  const handlePost = async () => {
    try {
      const formData = new FormData();
      formData.append('userId', _id);
      formData.append('description', post);
      //Append all images to the form data
      if (images && images.length > 0) {
        for (const image of images) {
          let compressedFile;
          if (image.file.type.startsWith('image/')) {
            compressedFile = await compressImage(image.file); //Compress the image
          } else {
            compressedFile = image.file; //If it's not an image or video, just use the original file
          }
          formData.append('picture', compressedFile); //Append the compressed image
          formData.append('picturePath', compressedFile.name); //Append the image path
        }
      }
      const response = await instance.post('/posts', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      const posts = response.data;
      dispatch(setPosts({ posts }));
      setImages([]); //Reset images after posting
      setPost('');
      setIsImage(false);
    } catch (error) {
      if (error.response.status === 401) {
        navigate('/login');
      }
      if (error.response.status === 403) {
        setError('Only confirmed members can add posts');
        setShowModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    setError(null);
    setShowModal(false);
  };

  const generatePreview = (file) => {
    if (file.type.startsWith('image/')) {
      return URL.createObjectURL(file); //Return object URL for image preview
    }
    if (file.type.startsWith('video/')) {
      return URL.createObjectURL(file);
      //return file; //If it's a video, handle it differently (eg. use a video thumbnail or a player)
    }
    return null;
  };

  return (
    <WidgetWrapper>
      <FlexBetween gap="1.5rem">
        <UserImage image={picturePath} />
        <InputBase
          name={`userPost-${_id}`}
          id={_id}
          placeholder="What's on your mind..."
          onChange={(e) => setPost(e.target.value)}
          value={post}
          multiline
          sx={{
            width: '100%',
            backgroundColor: palette.neutral.light,
            borderRadius: '2rem',
            padding: '1rem 2rem',
          }}
        />
      </FlexBetween>
      {isImage && (
        <Box
          borderRadius={'5px'}
          border={`1px solid ${medium}`}
          mt="1rem"
          p="1rem"
        >
          <Dropzone
            multiple={true}
            accept={{
              //For now we only accept images
              'image/jpg': [],
              'image/jpeg': [],
              'image/png': [],
            }}
            id={`dropzone-${_id}`}
            onDrop={(acceptedFiles) => {
              const filesWithPreview = acceptedFiles.map((file) => ({
                file,
                preview: generatePreview(file),
              }));
              setImages(filesWithPreview);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <FlexBetween>
                <Box
                  {...getRootProps()}
                  border={`2px dashed ${palette.primary.main}`}
                  p={'1rem'}
                  width="100%"
                  sx={{
                    '&:hover': { cursor: 'pointer' },
                  }}
                >
                  {images.length === 0 ? (
                    <>
                      <input {...getInputProps()} id={`photos-${_id}`} />
                      <p style={{ textAlign: 'center' }}>Add Media files</p>
                    </>
                  ) : (
                    <Box mt={'1rem'}>
                      <Grid container spacing={1}>
                        {images.map((image, indx) => (
                          <Grid item xs={4} key={indx}>
                            <Box key={indx} sx={{ marginBottom: '1rem' }}>
                              {image.file.type.startsWith('image/') && (
                                <Box
                                  sx={{
                                    position: 'relative',
                                    height: '100px',
                                    backgroundColor: palette.neutral.light,
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <img
                                    src={image.preview}
                                    alt={`preview-${indx}`}
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => {
                                      const newImages = images.filter(
                                        (_, i) => i !== indx,
                                      );
                                      setImages(newImages);
                                    }}
                                    sx={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    }}
                                  >
                                    <DeleteOutlined sx={{ color: 'white' }} />
                                  </IconButton>
                                </Box>
                              )}
                              {image.file.type.startsWith('video/') && (
                                <Box
                                  sx={{
                                    position: 'relative',
                                    display: 'flex',
                                  }}
                                >
                                  <video
                                    src={image.preview}
                                    controls
                                    style={{
                                      width: '100px',
                                      height: '100px',
                                      objectFit: 'cover',
                                      borderRadius: '8px',
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => {
                                      const newVideos = images.filter(
                                        (_, i) => i !== indx,
                                      );
                                      setImages(newVideos);
                                    }}
                                    sx={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    }}
                                  >
                                    <DeleteOutlined sx={{ color: 'white' }} />
                                  </IconButton>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                </Box>
                {images.length > 0 && (
                  <IconButton
                    onClick={() => setImages([])} //Clear images
                    sx={{ width: '15%' }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                )}
              </FlexBetween>
            )}
          </Dropzone>
        </Box>
      )}

      <Divider sx={{ margin: '1.25rem 0' }} />

      <FlexBetween>
        <FlexBetween gap="0.25rem" onClick={() => setIsImage(!isImage)}>
          <ImageOutlined sx={{ color: mediumMain }} />
          <Typography
            color={mediumMain}
            sx={{ '&:hover': { cursor: 'pointer', color: medium } }}
          >
            Image/Video
          </Typography>
        </FlexBetween>
        <Button
          disabled={!post}
          onClick={handlePost}
          sx={{
            color: palette.background.alt,
            backgroundColor: palette.primary.dark,
            borderRadius: '3rem',
          }}
        >
          POST
        </Button>
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              m: '15rem auto',
              p: '1rem',
              backgroundColor: palette.primary.main,
              color: palette.background.alt,
              '&:hover': {
                color: palette.primary.main,
              },
              borderRadius: '5px',
              width: 300,
              height: 200,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{ textAlign: 'center', mt: '1rem' }}
            >
              Oops... Something went wrong!!!
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="h6"
              component="h3"
              sx={{
                textAlign: 'center',
                mt: '2rem',
                backgroundColor: 'red',
              }}
            >
              {error}
            </Typography>
          </Box>
        </Modal>
      </FlexBetween>
    </WidgetWrapper>
  );
};

export default MyPostWidget;

import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
  DeleteForeverOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Typography,
  useTheme,
  IconButton,
  Grid,
  Modal,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import instance from "../../axios/api";
import FlexBetween from "components/FlexBetween";
import Member from "components/Member";
import WidgetWrapper from "components/WidgetWrapper";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Form from "./CommentForm";
import UserImage from "components/UserImage";
import { useNavigate } from "react-router-dom";

const PostWidget = ({
  postId,
  postUserId,
  name,
  description,
  location,
  picturePath,
  userPicturePath,
  likes,
  comments,
  deletePost,
  postTime,
}) => {
  const [isComment, setIsComment] = useState(false);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Track the Modal state
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const isLiked = Boolean(likes[loggedInUserId]);
  const likeCount = Object.keys(likes).length;
  const navigate = useNavigate();

  const isAdmin = useSelector((state) => state.user.role === "ADMIN");

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const dark = palette.neutral.dark;

  const isVideo = (mediaUrl) => {
    return (
      mediaUrl &&
      (mediaUrl.endsWith(".mp4") ||
        mediaUrl.endsWith(".webm") ||
        mediaUrl.endsWith(".ogg") ||
        mediaUrl.endsWith(".mov") ||
        mediaUrl.endsWith(".avi"))
    );
  };

  const patchLike = async () => {
    try {
      const response = await instance.patch(
        `/posts/${postId}/like`,
        {
          userId: loggedInUserId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const updatedPost = response.data;
      dispatch(setPost({ post: updatedPost }));
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login");
      }
      if (error.response.status === 403) {
        setError(error.response.data.message);
        setShowModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    setError(null);
    setShowModal(false);
  };

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsImageOpen(true);
  };

  const closeLightbox = () => {
    setIsImageOpen(false);
  };

  const generateSlides = (mediaPaths) =>
    mediaPaths.map((media) => ({
      src: media,
      type: isVideo(media) ? "video" : "image",
    }));

  const slides = useMemo(() => generateSlides(picturePath), [picturePath]);

  const isUserOwnPost = loggedInUserId === postUserId;

  return (
    <WidgetWrapper m="1.5rem 0">
      <FlexBetween gap="1.5rem">
        <Member
          userId={postUserId}
          name={name}
          location={location}
          userPicturePath={userPicturePath}
        />
        {(isUserOwnPost || isAdmin) && (
          <IconButton
            sx={{
              display: "flex",
              //position at the top
              color: dark,
            }}
            onClick={() => deletePost(postId)}
          >
            <DeleteForeverOutlined />
          </IconButton>
        )}
      </FlexBetween>
      <Typography
        color={dark}
        sx={{
          mt: "1rem",
          fontSize: "1rem",
          fontWeight: "500",
        }}
      >
        {description}
      </Typography>
      <Typography
        sx={{
          mt: "0.5rem",
          fontSize: "0.8rem",
          fontStyle: "italic",
          color: palette.neutral.medium,
        }}
      >
        {(() => {
          const date = new Date(postTime);

          const weekday = date.toLocaleDateString("en-US", {
            weekday: "short",
          }); // Get the day of the week in short format
          const month = date.toLocaleDateString("en-US", { month: "short" }); // Get the month in short format
          const day = date.getDate(); // Get the day of the month
          const year = date.getFullYear(); // Get the year
          const time = date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          });
          return `${weekday}, ${month} ${day}, ${year} at ${time}`;
        })()}
      </Typography>
      {picturePath && (
        <Grid container spacing={1} sx={{ marginTop: "0.75rem" }}>
          {picturePath.slice(0, 3).map((media, idx) => (
            <Grid item key={idx} xs={4}>
              <Box
                sx={{
                  position: "relative",
                  height: "200px",
                  backgroundColor: palette.neutral.light,
                  borderRadius: "8px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => openLightbox(idx)}
              >
                {isVideo(media) ? (
                  <video
                    width={"100%"}
                    height={"100%"}
                    style={{
                      objectFit: "cover",
                    }}
                    controls={false}
                    autoPlay
                  >
                    <source src={media} />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={media}
                    alt={`post-image-${idx}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
                {idx === 2 && picturePath.length > 3 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    +{picturePath.length - 3}
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      <FlexBetween mt="0.25rem">
        <FlexBetween gap="1rem">
          <FlexBetween gap="0.3rem">
            <IconButton onClick={patchLike}>
              <Badge
                badgeContent={likeCount}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                {isLiked ? (
                  <FavoriteOutlined sx={{ color: primary }} />
                ) : (
                  <FavoriteBorderOutlined />
                )}
              </Badge>
            </IconButton>
          </FlexBetween>

          <FlexBetween gap="0.3rem">
            <IconButton onClick={() => setIsComment(!isComment)}>
              <Badge
                badgeContent={comments.length}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <ChatBubbleOutlineOutlined />
              </Badge>
            </IconButton>
          </FlexBetween>
        </FlexBetween>

        <IconButton>
          <ShareOutlined />
        </IconButton>
      </FlexBetween>

      {isComment && (
        <Box mt="0.5rem">
          {comments.map((comment, idx) => (
            <Box key={`${name}-${idx}`}>
              <Divider />
              <FlexBetween m={"0.5rem 0"}>
                <FlexBetween gap={"0.3rem"}>
                  <Box
                    onClick={() => {
                      navigate(`/profile/${comment.userId}`);
                    }}
                  >
                    <UserImage
                      image={comment.userPicturePath}
                      size="35px"
                      sx={{ borderRadius: "50%" }}
                    />
                  </Box>
                  <Typography sx={{ color: dark, m: "0.5rem 0", pl: "1rem" }}>
                    {comment.text}
                  </Typography>
                </FlexBetween>
                <FlexBetween gap="0.3rem">
                  <IconButton onClick={() => setIsComment(!isComment)}>
                    <Badge badgeContent={Object.keys(comment.likes).length}>
                      <FavoriteBorderOutlined />
                    </Badge>
                  </IconButton>
                  {/* <Typography>{Object.keys(comment.likes).length}</Typography> */}
                </FlexBetween>
              </FlexBetween>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    //Align the element to the right
                    fontSize: "0.8rem",
                    fontStyle: "italic",
                    color: palette.neutral.medium,
                  }}
                  variant="caption"
                >
                  {(() => {
                    const date = new Date(comment.timeStamp);

                    const weekday = date.toLocaleDateString("en-US", {
                      weekday: "short",
                    }); // Get the day of the week in short format
                    const month = date.toLocaleDateString("en-US", {
                      month: "short",
                    }); // Get the month in short format
                    const day = date.getDate(); // Get the day of the month
                    const year = date.getFullYear(); // Get the year
                    const time = date.toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    });
                    return `${weekday}, ${month} ${day}, ${year} at ${time}`;
                  })()}
                </Typography>
              </Box>
            </Box>
          ))}
          <Divider />
          {isComment && <Form postId={postId} />}
        </Box>
      )}

      {isImageOpen && (
        <Lightbox
          open={isImageOpen}
          close={closeLightbox}
          slides={slides}
          index={currentImageIndex}
          onNavigate={(index) => {
            console.log("Navigating to image index", index);
            setCurrentImageIndex(index);
          }}
          plugins={[Zoom, Slideshow, Thumbnails]} // Add the Zoom plugin
          zoom={{
            maxZoomPixelRatio: 3, //Max zoom level
            zoomInMultiplier: 1.2, //Zoom in speed
          }}
        />
      )}

      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            m: "15rem auto",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": {
              color: palette.primary.main,
            },
            borderRadius: "5px",
            width: 300,
            height: 200,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ textAlign: "center", mt: "1rem" }}
          >
            Oops... Something went wrong!!!
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="h6"
            component="h3"
            sx={{
              textAlign: "center",
              mt: "2rem",
              backgroundColor: "red",
            }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </WidgetWrapper>
  );
};

export default PostWidget;

import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FlexBetween from "./FlexBetween";
import { useNavigate } from "react-router-dom";
import UserImage from "./UserImage";

const Member = ({ userId, name, location, userPicturePath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const member = useSelector((state) => state.user);

  const { palette } = useTheme();
  const primaryLight = palette.primary.main;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  const isVerifiedMember = member.status === "MEMBER";

  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        <Box
          onClick={() => {
            navigate(`/profile/${userId}`);
            // navigate(0);
          }}
        >
          <UserImage image={userPicturePath} size="55px" />
        </Box>
        <Box
          onClick={() => {
            navigate(`/profile/${userId}`);
            // navigate(0);
          }}
        >
          <Typography
            color={main}
            variant="h5"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
            }}
          >
            {name}
          </Typography>
          <Typography color={medium} fontSize="0.75rem">
            {location}
          </Typography>
        </Box>
      </FlexBetween>
    </FlexBetween>
  );
};

export default Member;

import { Box, useMediaQuery } from "@mui/material";
import Navbar from "../navbar";
import { useSelector } from "react-redux";
import MyPostWidget from "../../scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";

const FeedsPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const { _id, picturePath } = useSelector((state) => state.user);
  return (
    <Box>
      <Navbar />
      <Box
        width={"100%"}
        padding={"1rem 2%"}
        display={isNonMobileScreens ? "flex" : "block"}
        gap={"2rem"}
        justifyContent={"space-between"}
      >
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "0.3rem"}
        >
          <MyPostWidget picturePath={picturePath} />
          <PostsWidget userId={_id} />
        </Box>
      </Box>
    </Box>
  );
};
export default FeedsPage;

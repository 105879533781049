import { useState } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Search,
  DarkMode,
  LightMode,
  Notifications,
  Help,
  Menu,
  Close,
  Home,
  Group,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setMode, setLogout } from '../../state/index';
import { useNavigate } from 'react-router-dom';
import FlexBetween from '../../components/FlexBetween';
import { dark } from '@mui/material/styles/createPalette';

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
  const isAuth = Boolean(useSelector((state) => state.token));

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const background = theme.palette.background.default;
  const alt = theme.palette.background.alt;

  const name = isAuth ? `${user.firstName}` : 'Guest';

  return (
    <FlexBetween padding={'0.5rem 2%'} backgroundColor={alt}>
      <FlexBetween gap={'1.75rem'}>
        <img
          src="/assets/photos/Logo.png"
          alt="swaco logo"
          border-radius="20%"
          height={30}
          width={100}
          onClick={() => navigate('/')}
        />
        {/* </Typography> */}
        {isNonMobileScreens && (
          <FlexBetween
            backgroundColor={neutralLight}
            borderRadius={'9px'}
            gap={'3rem'}
            padding={'0.1rem 1.5rem'}
          >
            <InputBase id={`search-${user._id}`} placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween>
        )}
      </FlexBetween>
      {/* DESKTOP NAV */}
      {isNonMobileScreens ? (
        <FlexBetween gap={'2rem'}>
          <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === 'dark' ? (
              <DarkMode sx={{ fontSize: '25px' }} />
            ) : (
              <LightMode sx={{ color: dark, fontSize: '25px' }} />
            )}
          </IconButton>
          <Home sx={{ fontSize: '25px' }} />
          <Group sx={{ fontSize: '25px' }} />
          <Notifications sx={{ fontSize: '25px' }} />
          <Help sx={{ fontSize: '25px' }} />
          <FormControl variant="standard" value={name}>
            <Select
              value={name}
              sx={{
                backgroundColor: neutralLight,
                width: '150px',
                borderRadius: '0.25rem',
                p: '0.25rem 1rem',
                '& .MuiSvgIcon-root': {
                  pr: '0.25rem',
                  width: '3rem',
                },
                '& .MuiSelect-select:focus': {
                  backgroundColor: neutralLight,
                },
              }}
              input={<InputBase id="user" />} // Set the input value to the user's name" />}
            >
              <MenuItem value={name}>
                <Typography>{name}</Typography>
              </MenuItem>
              {isAuth && (
                <MenuItem onClick={() => dispatch(setLogout())}>
                  Log Out
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </FlexBetween>
      ) : (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          <Menu />
        </IconButton>
      )}

      {/* MOBILE NAV */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position={'fixed'}
          right={'0'}
          bottom={'0'}
          height={'100%'}
          zIndex={'10'}
          maxWidth={'500px'}
          minWidth={'300px'}
          backgroundColor={background}
        >
          {/* CLOSE ICON */}
          <Box display={'flex'} justifyContent={'flex-end'} p={'1rem'}>
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          {/* MENU ITEMS */}
          <FlexBetween
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'3rem'}
          >
            <IconButton
              onClick={() => dispatch(setMode())}
              sx={{ fontSize: '25px' }}
            >
              {theme.palette.mode === 'dark' ? (
                <DarkMode sx={{ fontSize: '25px' }} />
              ) : (
                <LightMode sx={{ color: dark, fontSize: '25px' }} />
              )}
            </IconButton>
            <Home sx={{ fontSize: '25px' }} onClick={() => navigate('/home')} />
            <Group
              sx={{ fontSize: '25px' }}
              onClick={() => navigate('/members')}
            />
            <Notifications sx={{ fontSize: '25px' }} />
            <Help sx={{ fontSize: '25px' }} />
            <FormControl variant="standard" value={name}>
              <Select
                value={name}
                sx={{
                  backgroundColor: neutralLight,
                  width: 'auto',
                  borderRadius: '0.25rem',
                  p: '0.25rem 1rem',
                  '& .MuiSvgIcon-root': {
                    pr: '0.25rem',
                    width: '3rem',
                  },
                  '& .MuiSelect-select:focus': {
                    backgroundColor: neutralLight,
                  },
                }}
                input={<InputBase id="user" />}
              >
                <MenuItem value={name}>
                  <Typography>{name}</Typography>
                </MenuItem>
                {isAuth && (
                  <MenuItem onClick={() => dispatch(setLogout())}>
                    Log Out
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};
export default Navbar;

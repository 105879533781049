import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "widgets/UserWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import { useParams } from "react-router-dom";
import axios from "../../axios/api";
import { useEffect, useState } from "react";

const ProfilePage = () => {
  const [profilePic, setProfilePic] = useState(null);

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const token = useSelector((state) => state.token);

  const { userId } = useParams();

  const getUser = async () => {
    const user = await axios.get(`/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setProfilePic(user.data.picturePath);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Box>
      <Navbar />
      <Box
        width={"100%"}
        padding={"2rem 6%"}
        display={isNonMobileScreens ? "flex" : "block"}
        gap={"2rem"}
        justifyContent={"space-between"}
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <UserWidget userId={userId} picturePath={profilePic} />
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <PostsWidget userId={userId} isProfile />
        </Box>
      </Box>
    </Box>
  );
};
export default ProfilePage;

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import FeedsPage from "./scenes/feeds/index";
import LoginPage from "./scenes/login";
import ProfilePage from "./scenes/profile";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Index from "./scenes/Landing";
import Constitution from "./scenes/constitution";
import LearnMore from "./scenes/learnMore";
import Members from "scenes/members";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="app">
      <BrowserRouter
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/home" element={<Index />} />
            <Route path="/members" element={<Members />} />
            <Route path="/constitution" element={<Constitution />} />
            <Route
              path="/"
              element={isAuth ? <Navigate to="/feeds" /> : <Index />}
            />
            <Route
              path="/login"
              element={isAuth ? <Navigate to="/feeds" /> : <LoginPage />}
            />
            <Route
              path="/feeds"
              element={isAuth ? <FeedsPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/profile/:userId"
              element={isAuth ? <ProfilePage /> : <Navigate to="/login" />}
            />
            <Route path="/learnmore" element={<LearnMore />} />
            <Route
              path="/joinus"
              element={
                isAuth ? <Navigate to="/feeds" /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/signup"
              element={
                isAuth ? <Navigate to="/feeds" /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/login"
              element={
                isAuth ? <Navigate to="/feeds" /> : <Navigate to="/login" />
              }
            />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Avatar,
  IconButton,
  Box,
  Typography,
  Modal,
  useTheme,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { useNavigate } from 'react-router-dom';
import axios from '../axios/api';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const UserCard = ({ member }) => {
  const token = useSelector((state) => state.token);
  const isAdmin = useSelector((state) => state.user.role === 'ADMIN');

  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [meetingMember, setmeetingMember] = useState(member);
  const formatPhoneNumber = (phoneNumber) => {
    //Remove the leading 1
    phoneNumber = phoneNumber.slice(1);
    return phoneNumber
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  const navigate = useNavigate();
  const { palette } = useTheme();

  const handleAddAdmin = async () => {
    try {
      const response = await axios.get(`/users/${meetingMember._id}/admin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setmeetingMember(response.data);
    } catch (err) {
      setError(error.response.data.message);
      setShowModal(true);
    }
  };

  //Create a random assortment of colors
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleRemoveAdmin = async () => {
    try {
      const response = await axios.delete(`/users/${meetingMember._id}/admin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setmeetingMember(response.data);
    } catch (err) {
      setError(error.response.data.message);
      setShowModal(true);
    }
  };

  const handleAddMember = async () => {
    try {
      const response = await axios.get(`/users/${meetingMember._id}/member`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data !== null && response.data !== undefined) {
        setmeetingMember(response.data);
      } else {
        setError('Cannot remove member. Try again.');
        setShowModal(true);
      }
    } catch (err) {
      if (err.response.data !== null && err.response.data !== undefined) {
        setError(error.response.data.message);
        setShowModal(true);
      } else {
        setError('Cannot remove member. Try again.');
        setShowModal(true);
      }
    }
  };

  const handleRemoveMember = async () => {
    try {
      const response = await axios.get(`/users/${meetingMember._id}/verified`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data !== null && response.data !== undefined) {
        setmeetingMember(response.data);
      } else {
        setError('Cannot remove member. Try again.');
        setShowModal(true);
      }
    } catch (err) {
      if (err.response.data !== null && err.response.data !== undefined) {
        setError(error.response.data.message);
        setShowModal(true);
      } else {
        setError('Cannot remove member. Try again.');
        setShowModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    setError(null);
    setShowModal(false);
  };

  return (
    <Card sx={{ m: '1rem' }}>
      <CardHeader
        onClick={() => navigate(`/profile/${meetingMember._id}`)}
        avatar={
          <Avatar
            sx={{ bgcolor: getRandomColor(), width: 30, height: 30 }}
            aria-label="user"
          >
            {meetingMember.lastName && meetingMember.lastName.charAt(0)}
          </Avatar>
        }
        subheaderTypographyProps={{ align: 'left' }}
        title={meetingMember.firstName}
        subheader={meetingMember.location ? meetingMember.location : null}
        sx={{ bgcolor: 'primary.light', p: '0.5rem', mb: '0.5rem' }}
      />
      <CardMedia
        onClick={() => navigate(`/profile/${meetingMember._id}`)}
        component="img"
        height="140"
        // crossorigin="anonymous"
        image={meetingMember.picturePath}
        alt={meetingMember.firstName}
      />
      {meetingMember.phoneNumber && (
        <CardContent sx={{ p: '0.1rem', mx: '0.3rem' }}>
          <Typography variant="subtitle1" sx={{ my: '0.5rem' }}>
            Name: {meetingMember.lastName}
          </Typography>
          <Typography variant="subtitle1" sx={{ my: '0.5rem' }}>
            Tel: {formatPhoneNumber(meetingMember.phoneNumber)}
          </Typography>
        </CardContent>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: '0.1rem',
        }}
      >
        {isAdmin && (
          <>
            <CardActions>
              {meetingMember.status === 'MEMBER' ? (
                <IconButton onClick={handleRemoveMember}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleAddMember}>
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
            </CardActions>
            <CardActions>
              {meetingMember.role === 'ADMIN' ? (
                <IconButton onClick={handleRemoveAdmin}>
                  <GroupRemoveIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleAddAdmin}>
                  <GroupAddIcon />
                </IconButton>
              )}
            </CardActions>
          </>
        )}
      </Box>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            m: '15rem auto',
            p: '1rem',
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            '&:hover': {
              color: palette.primary.main,
            },
            borderRadius: '5px',
            width: 300,
            height: 200,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ textAlign: 'center', mt: '1rem' }}
          >
            Oops... Something went wrong!!!
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="h6"
            component="h3"
            sx={{
              textAlign: 'center',
              mt: '2rem',
              backgroundColor: 'red',
            }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </Card>
  );
};

export default UserCard;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import instance from "../../axios/api";
import { SendOutlined } from "@mui/icons-material";
import {
  IconButton,
  InputBase,
  Modal,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const CommentForm = ({ postId }) => {
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const { palette } = useTheme();
  const primary = palette.primary.main;

  const handleComment = async (e) => {
    try {
      e.preventDefault();
      if (comment) {
        const response = await instance.patch(
          `/posts/${postId}/comment`,
          {
            text: comment,
            userId: user._id,
            userPicturePath: user.picturePath,
            firstName: user.firstName,
            id: postId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const updatedPost = response.data;
        dispatch(setPost({ post: updatedPost }));
        setComment("");
      }
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
      }
      if (err.response.status === 403) {
        setError("Only members can comment on posts");
        setShowModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    setError(null);
    setShowModal(false);
  };

  return (
    <WidgetWrapper>
      <FlexBetween gap="0.5rem">
        <InputBase
          placeholder="Write a comment..."
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          autoFocus={true}
          id={`comment-${user._id}-${postId}`}
          multiline
          sx={{
            width: "100%",
            backgroundColor: palette.neutral.light,
            borderRadius: "0.5rem",
            padding: "1rem 2rem",
          }}
        />
        <IconButton
          onClick={handleComment}
          disabled={!comment}
          sx={{
            backgroundColor: primary,
            padding: "0.2rem",
            borderRadius: "50%",
          }}
        >
          <SendOutlined />
        </IconButton>
      </FlexBetween>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            m: "15rem auto",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": {
              color: palette.primary.main,
            },
            borderRadius: "5px",
            width: 300,
            height: 200,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ textAlign: "center", mt: "1rem" }}
          >
            Oops... Something went wrong!!!
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="h6"
            component="h3"
            sx={{
              textAlign: "center",
              mt: "2rem",
              backgroundColor: "red",
            }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </WidgetWrapper>
  );
};

export default CommentForm;

import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost";
const apiPort = process.env.REACT_APP_API_PORT || "3001"; // Default port

const baseURL = `${apiUrl}:${apiPort}`;

const instance = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default instance;

import { Box, useTheme } from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import Navbar from "scenes/navbar";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "../../axios/api";
import UserCard from "components/UserCard";
import { useMediaQuery } from "@mui/material";

/**
 * A component that displays a list of members.
 *
 * Fetches member data from the API and stores it in the state.
 * Utilizes the Navbar component and arranges content based on screen size.
 */

const Members = () => {
  const theme = useTheme();
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const [members, setMembers] = useState([]);

  const fetchMembers = async () => {
    try {
      const response = await axios.get("/users/members", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMembers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          width: isNonMobileScreens ? "80%" : "100%", // Adjust width based on screen size
          padding: "0.2rem 2%",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))", // Dynamic columns
        }}
        margin="0 auto" // Center the grid
      >
        {members.map((member) => (
          <UserCard key={member._id} member={member} />
        ))}
      </Box>
    </Box>
  );
};

export default Members;

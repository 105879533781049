import imageCompression from "browser-image-compression";

//Function to handle image compression and resizing
/**
 *
 * @param {file} file - The original image file
 * @returns Promise<File> - A promise that resolves to the compressed image file
 */
const compressImage = async (file) => {
  //Only compress if the file is an image
  if (!file.type.startsWith("image/")) {
    return file;
  }

  const options = {
    maxSizeMB: 1, //Max size in MB
    maxWidthOrHeight: 1024, //Max width or height
    useWebWorker: true, //Use a web worker to compress the image for performance
  };

  try {
    const compressedBlob = await imageCompression(file, options);
    return new File([compressedBlob], file.name, {
      type: file.type,
    });
  } catch (error) {
    console.error("Error compressing image", error);
    return file;
  }
};

export default compressImage;
